export const environment = {
  countryCode: 'FI',
  defaultLocation: [61.05871, 28.18871],
  feedbackCategoryLevels: ['group', 'description', 'service_code'] as const,
  jurisdiction: 'Lappeenranta',
  privacyPolicyUrl: 'https://kartta.lappeenranta.fi/eFeedback/fi/PrivacyPolicy',
  production: true,
  radarApiKey: 'prj_live_pk_9d0ccb56941b0edaab1da1076c238b7798b959a1',
  sentryDsn: 'https://941200b1b674c015a485c1a3cee5fdff@o135002.ingest.us.sentry.io/4507050402709504',
  serviceApiApiKey: 'agnXukGsFvC49nEHZEIEH0XRVcnIvPdd',
  serviceApiJurisdictionId: 'citizenapp',
  serviceApiUrl: 'https://kartta.lappeenranta.fi/efeedback/api/georeport/6aika',
  streetAiApiJurisdictionId: 'lappeenranta',
  streetAiApiKey: 'MqWGlachcv7qXUuaxa69Y3mR0dI5E7fna0YHURQY',
  streetAiApiUrl: 'https://external.streetai.net/api/v1',
  streetAiUploadUrl: 'https://opendata.streetai.net/uploads',
  termsOfUseUrl: 'https://kartta.lappeenranta.fi/eFeedback/fi/TermsOfUse',
  trackingCode: 'G-BE9DHH3SEG'
};
